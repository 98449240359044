import React from "react";
import Layout from "../layouts/Layout";



const Termsconditions = () => {
  return (
    <Layout header={1} footer={3} className="" mainClassName="fix">

<section className="privacybnr" >
        <div className="container">
            <div className="row">
            <div className="col-xxl-12 col-12">
              <h2>Terms and Conditions</h2>
            </div>
               
            </div>
        </div>
     </section>
      
 <section className="privacy-content">
    <div className="container">
        <div className="row">
        <div className="col-xxl-12 col-12">
        <div className="consent">
         <h2 className="prolu">Terms and Conditions</h2>
          <p className="safeguarding">
          These Terms and Conditions ("Terms") govern your use of the services provided by NexaBuild Construction ("NexaBuild," "we," "us," or "our") including but not limited to construction, interior design, and exterior design services. By accessing or using our services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our services.
          </p>
          <h3>Service Provision:</h3>
          <ul>
            <li>NexaBuild provides construction, interior design, and exterior design services as described on our website and agreed upon in contracts with clients.</li>
            <li>We reserve the right to refuse service to anyone for any reason at any time.</li>
          </ul>
          <h3> Client Responsibilities:</h3>
          <ul>
            <li>Clients are responsible for providing accurate and complete information necessary for the provision of services.</li>
            <li>Clients must adhere to agreed-upon schedules and timelines and provide access to necessary premises for the completion of services.</li>
          </ul>
          <h3>Payment:</h3>
          <ul>
          <li>Payment terms will be outlined in the contract between NexaBuild and the client.</li>
          <li>Clients are responsible for timely payment of invoices according to the agreed-upon terms.</li>
          </ul>
        <h3>Intellectual Property:</h3>
        <ul>
          <li>All intellectual property rights, including but not limited to designs, plans, and drawings, created by NexaBuild during the provision of services, remain the property of NexaBuild unless otherwise agreed upon in writing.</li>
        </ul>
        <h3>Limitation of Liability:</h3>
        <ul>
          <li>NexaBuild shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to the provision of services.</li>
          <li>In no event shall NexaBuild's liability exceed the total amount paid by the client for the services provided.</li>
        </ul>
        <h3> Indemnification:</h3>
        <ul>
          <li>Clients agree to indemnify and hold NexaBuild and its affiliates, employees, and agents harmless from any claims, damages, or losses arising out of or related to the client's use of our services.</li>
        </ul>
        <h3>Confidentiality:</h3>
        <ul>
          <li>Both NexaBuild and the client agree to maintain the confidentiality of any confidential information disclosed during the provision of services.</li>
        </ul>
        <h3>Termination:</h3>
        <ul>
          <li>Either party may terminate the provision of services by providing written notice to the other party.</li>
          <li>Upon termination, the client shall pay NexaBuild for all services provided up to the date of termination.</li>
        </ul>
        <h3> Governing Law:</h3>
        <ul>
          <li>These terms shall be governed and construed in accordance with the English laws. </li>
        </ul>
        <h3>Amendments:</h3>
        <ul>
          <li>NexaBuild reserves the right to update or modify these Terms at any time without prior notice. By continuing to use our services after any such changes, you agree to be bound by the updated Terms.</li>
        </ul>
        <h3>Entire Agreement:</h3>
        <ul>
          <li>These Terms constitute the entire agreement between NexaBuild and the client regarding the provision of services and supersede all prior agreements and understandings, whether written or oral.</li>
        </ul>

         <p className="bold-t">Contact us   </p>
            <p className="safeguarding">If you have any questions or queries about our privacy policy, feel free to contact us at <strong><a href="info@nexabuild-construction.co.uk">info@nexabuild-construction.co.uk.</a></strong>. 
            </p>

          </div>   
          </div>
        </div>
    </div>
 </section>
    </Layout>
  );
};

export default Termsconditions;
