import React from "react";
import thankuo from "../../src/assets/image/thankuo.png";
import HeaderTwo from "../components/Headers/HeaderTwo";
import FooterThree from "../components/Footers/FooterThree";
import {Helmet} from "react-helmet";

const Thankyou = () => {
  return (
    <>
      <HeaderTwo className="" mainClassName="fix" />
      <Helmet>
  <meta charSet="utf-8" />
  <title>Thank You</title>
  </Helmet>
      <section className="Thankyou1">
      <div className="container">
        <div className="row">
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
        <div className="Thankouter">
        <h2 class="site-header__title" data-lead-id="site-header-title">Thank You!</h2>
        <div class="main-content">
        <img src={thankuo} className="thankuo" alt="" />
    <p class="main-content__body" data-lead-id="main-content-body">Thank you for taking out your valuable time to fill out our form. We’ve got your details, and we will be in touch shortly to discuss the next steps. Looking forward to serving you in the best possible way. </p>
    </div>
  </div>
        </div>
        </div>
      </div>
      </section>
      <FooterThree className="" mainClassName="fix" />
    </>
     );
};

export default Thankyou;
