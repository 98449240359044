import { useEffect } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import WOW from "wow.js";

import ContactPage from "./pages/ContactPage";
import BlogPage from "./pages/Blog/BlogPage";
import BlogDetailsPage from "./pages/Blog/BlogDetailsPage";
import About from "./pages/About";
import Privacypolicy from "./pages/Privacypolicy";
import Termsconditions from "./pages/Terms-conditions";
import Thankyou from "./pages/Thank-you";
import LoftConversions from "./pages/Loft-Conversions";
import DampProofing from "./pages/Damp-Proofing";
import Driveways  from "./pages/Driveways";
import HouseExtensions from "./pages/House-Extensions";
import Kitchen from "./pages/Kitchen";
import NewBuilds from "./pages/New-Builds";
import Renovation from "./pages/Renovation";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
// import AnimatedCursor from "react-animated-cursor";

import Home from "./pages/Home";

function App() {
  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {/* <AnimatedCursor
        innerSize={8}
        outerSize={32}
        color="248, 158, 82"
        outerAlpha={0.15}
        innerScale={0}
        outerScale={0}
        clickables={[
          "a",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "textarea",
          "button",
          ".link",
        ]}
      /> */}

      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Gallery" element={<Gallery />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/About" element={<About />} />
      <Route path="/Renovation" element={<Renovation />} />
      <Route path="/New-Builds" element={<NewBuilds />} />
      <Route path="/Kitchen" element={<Kitchen />} />
      <Route path="/House-Extensions" element={<HouseExtensions />} />
      <Route path="/Driveways" element={<Driveways />} />
      <Route path="/Damp-Proofing" element={<DampProofing />} />
      <Route path="/Loft-Conversions" element={<LoftConversions />} />
      <Route path="/Thank-you" element={<Thankyou />} />
      <Route path="/Privacypolicy" element={<Privacypolicy />} />
      <Route path="/Terms-conditions" element={<Termsconditions />} />
   
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog-details" element={<BlogDetailsPage />} />
      </Routes>
    </>
  );
}

export default App;
