import React from "react";
import dampbnr from "../../src/assets/image/dampbnr.webp";
import rampside from "../../src/assets/image/rampside.webp";
import lighthousebg from "../../src/assets/image/lighthousebg.png";
import moisturizing from "../../src/assets/image/moisturizing.png";
import membrane from "../../src/assets/image/membrane.png";
import Waterproof from "../../src/assets/image/Waterproof.png";
import MoistureControl from "../../src/assets/image/MoistureControl.png";
import SimilarProjects from "../components/ServicesArea/SimilarProjects";
import Queryform from "../components/Queryform";
import damplow from "../../src/assets/image/damplow.webp";
import HeaderTwo from "../components/Headers/HeaderTwo";
import FooterThree from "../components/Footers/FooterThree";
import {Helmet} from "react-helmet";
import Preloader from "../components/Preloader/Preloader";
import ScrollTop from "../components/ScrollTop/ScrollTop";

const DampProofing = () => {
  return (
    <>
    <Preloader />
    <ScrollTop />
      <HeaderTwo className="" mainClassName="fix" />
      <Helmet>
  <meta charSet="utf-8" />
  <title>Damp-Free Living: Professional Solutions for Damp Proofing Your Home</title>
  </Helmet>
      <section className="section image-infinite-bg position-relative animation-bg"
       style={{
      backgroundImage: "url(" + dampbnr + ")", backgroundSize: "cover",backgroundRepeat: "no-repeat",backgroundPosition: "center", padding: "15em 1em",}} >

    <div className="r-container h-100">
      <div className="image-overlay" />
      <div
        className="d-flex flex-column justify-content-center align-items-center text-center gap-3 h-100 position-relative"
        style={{ zIndex: 2 }} >
        <h1 className="text-title text-white bnnerheading">Trusted Damp <br /><span className='lightspan'>Proofing Services</span></h1>
          <div className="d-flex flex-row gap-3">
          <div class="bnnerbtn">
           <a class="site-button btn-half" href="tel:07710 519713"><span>Contact us now</span></a>
           </div>
          
         
        </div>
      </div>
    </div>
  </section>
  <section className='interior-center'>
    <div className='container'>
      <div className='row'>
       <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
         <div className='sidevideo'>
         <img src={rampside} className='converstion1' alt='converstion1' />
          </div>
             </div>
               <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <h2 className='experienced'> Durable Damp Proofing 
                   <span className='lightspan'> Tailored To Your Home</span></h2>
                     <p className='visualize'>Damp proofing is crucial in construction to prevent moisture damage. Our solutions ensure durability and longevity for your projects.</p>
                     <p className='visualize'>We employ advanced techniques and high-quality materials, offering reliable protection against dampness, safeguarding structures for years to come.</p>
                       <div className='row'>
                         <div className='col-xl-6 col-xl-6 col-lg-12 col-md-12 col-12'>
                            <ul  className="list-angle-right anchor-line retail">
                              <li>Prevents structural damage.</li>
                                <li>Increases longevity.</li>
                                 <li>Enhances indoor air quality.</li>
                                  <li>Protects interior finishes.</li>
                                   </ul>           
                                </div>
                                <div className='col-xl-6 col-xl-6 col-lg-12 col-md-12 col-12' id='Projects'>
                                  <ul  className="list-angle-right anchor-line retail">
                                    <li>Reduces energy costs.</li>
                                    <li>Maintains property value.</li>
                                    <li>minimises health risks.</li>
                                     <li>Improves aesthetics.</li>
                                     </ul>           
                                </div>
                             </div>
                            
                              </div>
                        </div>
                    </div>
     </section>           
     <section className='inner-serivces'>
 <img src={lighthousebg} className='lighthousebg' alt='' />
    <div className='container'>
        <div className='row' id='dynmic'>
            <div className='col-xxl-7 col-xl-lg-7 col-md-12 col-12'>
                <h2>Comprehensive Damp Proofing  <span className='lightspan'>Solutions for Construction</span></h2>
                <p className='visualize'>Our damp proofing services offer comprehensive solutions to safeguard constructions, ensuring long-term durability and protection against moisture-related issues</p>
            </div>
        </div>
        <div className='row' id='two'>
         <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
          <img src={moisturizing} className='commcail' alt='' />
          <h3 className='Commercial'>Moisture Diagnosis</h3>
            <p>Professional assessment identifies sources, enabling precise solutions to combat dampness effectively, ensuring structural integrity.</p>
            </div>
           <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={membrane} className='commcail' alt='' />
            <h3 className='Commercial'>Membrane Installation </h3>
            <p>Skilled application of robust membranes creates impermeable shields, safeguarding against moisture infiltration, prolonging structural lifespan.</p>
             </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={Waterproof} className='commcail' alt='' />
            <h3 className='Commercial'>Waterproof Coatings </h3>
            <p>Application of high-quality coatings fortifies surfaces, resisting water ingress, and preserving aesthetics, ensuring long-term protection.</p>
             </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={MoistureControl} className='commcail' alt='' />
            <h3 className='Commercial'>Moisture Control System</h3>
            <p>customise implementation of advanced systems manages moisture levels, creating ideal environments for sustained structural durability and performance.</p>
             </div>
             </div>
             </div>
            </section>  
      
    <section className='our-projects'>
     <div className='container-fluid'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='fields-text'>
                <h2 className='Offering'>We specialise in every aspect of internal <br /> <span className='light'>and external building projects</span></h2>
            <SimilarProjects />
                </div>
             
            </div>
        </div>
    </div>
</section>
   <section className='benefits'>
    <div className='container'>
        <div className='row' id='benerow'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12'>
                <h2>Why choose us for your <span className="lightspan">damp proofing needs?</span></h2>
                <p className='underwhelm'>As damp proofing specialists in the UK, we have carried out hundreds of projects for our clients. You can trust us for long-lasting and cost-effective damp-proofing solutions tailored to your home. </p>
            </div>
        </div>
        <div className='row' id='inexpensive'>
         <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12' >
          <img src={damplow} className='construction-center' alt='' />
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12' >
            <div className='Exteriorbox'>
             <p className='Competently'><span className='number'> 1.</span>Our smooth-running team is made of qualified professionals who are experts in damp proofing and have the expertise to handle the most challenging damp issues. </p>
            <hr />
            <p className='Competently'><span className='number'> 2.</span>Before commencing the project, we establish time and budget expectations with our clients. We always strive to finish our projects on time and within budget.</p>
            <hr />
            <p className='Competently'><span className='number'> 3.</span>Our customers are our highest priority. From start to finish, we work collaboratively with you for a smooth and hassle-free experience. </p>
            </div>
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12' >
            <div className='Exteriorbox'>
             <p className='Competently'><span className='number'> 4.</span>Our dedicated team prioritises quality at every step. We provide you with long-lasting solutions that adhere to the highest standards of quality and craftsmanship.</p>
            <hr />
            <p className='Competently'><span className='number'> 5.</span>Whether you’re dealing with rising damp, penetrating damp, condensation or mould growth, we have comprehensive solutions for all your needs. </p>
            <hr />
            <p className='Competently'><span className='number'> 6.</span>We use the highest quality materials and products for damp-proofing treatments to ensure long-term protection of your home from moisture damage. </p>
            </div>
            </div>
            </div>        
        </div>

</section>
<hr />
<section className='innercta'>
  <div className='container'>
    <div className='row'>
      <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12'>
        <div className='cta-text'>
          <h3>Wanna Work With Our Profesional Team? <span className='lightspan'>Make an Appointment.</span></h3>
         
        </div>
      </div>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
      
      <div className="button float-right">
      <Queryform />
</div>
      </div>
    </div>
  </div>
</section>  
      <FooterThree className="" mainClassName="fix" />
    </>
    );
};

export default DampProofing;
