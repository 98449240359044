import React from "react";
import aboutside from "../../src/assets/image/aboutside.png";
import Aboutbnr from "../../src/assets/image/Aboutbnr.webp";
import HeaderTwo from "../components/Headers/HeaderTwo";
import FooterThree from "../components/Footers/FooterThree";
import {Helmet} from "react-helmet";
import Preloader from "../components/Preloader/Preloader";
import ScrollTop from "../components/ScrollTop/ScrollTop";


const Contact = () => {
  return (
    <>
    <Preloader />
    <ScrollTop />
      <HeaderTwo className="" mainClassName="fix" />
      <Helmet>
  <meta charSet="utf-8" />
  <title>NexaBuild: Contact Us</title>
  </Helmet>
      <section className="section image-infinite-bg position-relative animation-bg"
       style={{
      backgroundImage: "url(" + Aboutbnr + ")", backgroundSize: "cover",backgroundRepeat: "no-repeat",backgroundPosition: "center", padding: "15em 1em",}} >

    <div className="r-container h-100">
      <div className="image-overlay" />
      <div
        className="d-flex flex-column justify-content-center align-items-center text-center gap-3 h-100 position-relative"
        style={{ zIndex: 2 }} >
        <h1 className="text-title text-white bnnerheading">We're Here To <br /><span className='lightspan'>Assist You </span></h1>
          <div className="d-flex flex-row gap-3">
          <div class="bnnerbtn">
           <a class="site-button btn-half" href="tel:07710 519713"><span>Contact us now</span></a>
           </div>
          
         
        </div>
      </div>
    </div>
  </section>
  <section className='interior-center'>
    <div className='container'>
      <div className='row'>
       <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
         <div className='sidevideo'>
         <img src={aboutside} className='converstion1' alt='converstion1' />
          </div>
             </div>
               <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <div className="contpg">
                 <form action="contact.php"
       method="post"
         encType="multipart/form-data"
          id="contform">
            <h2>Get in Touch <span className="lightspan">with us</span> </h2>
            <input type="text" name="name" placeholder="Name" className="reg-input" required />
            <input type="email" name="email" placeholder="Email" className="reg-input" required />
            <input type="tel" name="phone" placeholder="Phone No" className="reg-input" required />
            <input type="text" name="subject" placeholder="Subject" className="reg-input" required />
            <textarea
              name="message"
               placeholder='Write message'
                defaultValue={""}
                 id='message'
                 />
            <button className='btn btn-base' type='submit'>
             Submit
            </button>
          
              
          
              </form>
                 </div>
                            
                              </div>
        </div>
        <div className='row text-center' id='twocontact'>
           <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
        <div className="contact-icon">
        <i class="fa-solid fa-location-dot"></i>
        </div>
          <h3 className='Commercial'>Our location</h3>
          <p className="cont-add">162 Market St, M43 7AA</p>
               </div>
               <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
               <div className="contact-icon">
               <i class="fa-regular fa-envelope"></i>
               </div>
                <h3 className='Commercial'>Email Us</h3>
                <p className="cont-add"><a href='mailto:info@nexabuild-construction.co.uk'>info@nexabuild-construction.co.uk</a> </p>
                 </div>
                 <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
                 <div className="contact-icon">
                 <i class="fa-solid fa-mobile-screen-button"></i>
               </div>
               <h3 className='Commercial'>Contact Us</h3>
                   <p className="cont-add"> <a href='tel:07773 005221'>07773 005221</a> </p>
                   
                      </div>
                   <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
                   <div className="contact-icon">
                   <i class="fa-solid fa-mobile-screen-button"></i>
               </div>
               <h3 className='Commercial'>Contact Us</h3>
                   <p className="cont-add"><a href='tel:07710 519713'>07710 519713</a> </p>
                        </div>
                        </div>   
                    </div>
     </section>        
      <FooterThree className="" mainClassName="fix" />
    </>
 
  );
};

export default Contact;
