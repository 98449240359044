import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import {
    FaCalculator,
    FaFileAlt,
    FaMapMarkerAlt,
    FaPencilAlt,
    FaPhoneAlt,
    FaRegEnvelope,
    FaUserAlt,
  } from "react-icons/fa";


const Queryform = () => {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
  
  return (
    <div>
    <button className-="newbtn" onClick={onOpenModal}>Get A Quote</button>
    <Modal open={open} onClose={onCloseModal} center>
   <div className='form-modal'>
   <form action="contact.php"
       method="post"
         encType="multipart/form-data"
          id="contform">
            <h5>Get in Touch with us </h5>
            <input type="text" name="name" placeholder="Name" className="reg-input" required />
            <input type="email" name="email" placeholder="Email" className="reg-input" required />
            <input type="tel" name="phone" placeholder="Phone No" className="reg-input" required />
            <input type="text" name="subject" placeholder="Subject" className="reg-input" required />
            <textarea
              name="message"
               placeholder='Write message'
                defaultValue={""}
                 id='message'
                 />
            <button className='btn btn-base' type='submit'>
             Submit
            </button>
          
              
          
              </form>
   </div>
    </Modal>
  </div>
  );
}

export default Queryform;