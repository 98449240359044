import React from "react";
import newbuild from "../../src/assets/image/newbuild.webp";
import newbuildside from "../../src/assets/image/newbuildside.webp";
import lighthousebg from "../../src/assets/image/lighthousebg.png";
import craftsman from "../../src/assets/image/craftsman.png";
import inspection from "../../src/assets/image/inspection.png";
import collaborate from "../../src/assets/image/collaborate.png";
import supportc from "../../src/assets/image/supportc.png";
import SimilarProjects from "../components/ServicesArea/SimilarProjects";
import Queryform from "../components/Queryform";
import constructioncenter from "../../src/assets/image/construction-center.png";
import HeaderTwo from "../components/Headers/HeaderTwo";
import FooterThree from "../components/Footers/FooterThree";
import {Helmet} from "react-helmet";
import Preloader from "../components/Preloader/Preloader";
import ScrollTop from "../components/ScrollTop/ScrollTop";

const NewBuilds = () => {
  return (
    <>
    <Preloader />
    <ScrollTop />
      <HeaderTwo className="" mainClassName="fix" />
      <Helmet>
  <meta charSet="utf-8" />
  <title>Building Your Vision: Innovative Construction Solutions for New Builds</title>
  </Helmet>
      <section className="section image-infinite-bg position-relative animation-bg"
       style={{
      backgroundImage: "url(" + newbuild + ")", backgroundSize: "cover",backgroundRepeat: "no-repeat",backgroundPosition: "center", padding: "15em 1em",}} >

    <div className="r-container h-100">
      <div className="image-overlay" />
      <div
        className="d-flex flex-column justify-content-center align-items-center text-center gap-3 h-100 position-relative"
        style={{ zIndex: 2 }} >
        <h1 className="text-title text-white bnnerheading">New Build <br /><span className='lightspan'>Construction Experts</span></h1>
          <div className="d-flex flex-row gap-3">
          <div class="bnnerbtn">
           <a class="site-button btn-half" href="tel:07710 519713"><span>Contact us now</span></a>
           </div>
          
         
        </div>
      </div>
    </div>
  </section>
  <section className='interior-center'>
    <div className='container'>
      <div className='row'>
       <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
         <div className='sidevideo'>
         <img src={newbuildside} className='converstion1' alt='converstion1' />
          </div>
             </div>
               <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <h2 className='experienced'> Innovative Construction Solutions
                   <span className='lightspan'> for New Build Projects</span></h2>
                     <p className='visualize'>For new builds, we offer cutting-edge construction solutions tailored to your vision, ensuring quality craftsmanship and timely project completion.</p>
                     <p className='visualize'>Our experienced team oversees every aspect, from planning to execution, guaranteeing seamless integration of innovative techniques for superior results.</p>
                       <div className='row'>
                         <div className='col-xl-6 col-xl-6 col-lg-12 col-md-12 col-12'>
                            <ul  className="list-angle-right anchor-line retail">
                              <li>Customisation  Design</li>
                                <li>Quality Craftsmanship</li>
                                 <li>Timely Completion</li>
                                  <li>Cost-Effective Solutions</li>
                                   </ul>           
                                </div>
                                <div className='col-xl-6 col-xl-6 col-lg-12 col-md-12 col-12' id='Projects'>
                                  <ul  className="list-angle-right anchor-line retail">
                                    <li>Energy Efficiency</li>
                                    <li>Innovative Features</li>
                                    <li>Safety Compliance</li>
                                     <li>Property Value</li>
                                     </ul>           
                                </div>
                             </div>
                            
                              </div>
                        </div>
                    </div>
     </section>           
     <section className='inner-serivces'>
 <img src={lighthousebg} className='lighthousebg' alt='' />
    <div className='container'>
        <div className='row' id='dynmic'>
            <div className='col-xxl-7 col-xl-lg-7 col-md-12 col-12'>
                <h2>Building Brilliance: Unveiling <span className='lightspan'>Our Construction Service Benefits</span></h2>
                <p className='visualize'>Experience unparalleled craftsmanship and attention to detail with our construction services, ensuring your project exceeds expectations every step of the way.</p>
            </div>
        </div>
        <div className='row' id='two'>
         <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
          <img src={craftsman} className='commcail' alt='' />
          <h3 className='Commercial'>  Customised Design </h3>
            <p>Tailored architectural and interior design plans crafted to meet your specific project requirements and preferences.</p>
            </div>
           <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={inspection} className='commcail' alt='' />
            <h3 className='Commercial'>Quality Construction Work </h3>
            <p>Expert construction services using premium materials and skilled craftsmanship, ensuring structural integrity and durability.</p>
             </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={collaborate} className='commcail' alt='' />
            <h3 className='Commercial'>Project Management </h3>
            <p>Comprehensive project management from inception to completion, overseeing timelines, budgets, and quality control for seamless execution.</p>
             </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={supportc} className='commcail' alt='' />
            <h3 className='Commercial'>Post-Construction Support </h3>
            <p>Ongoing support and maintenance services post-construction to ensure the long-term performance and satisfaction of your project.</p>
             </div>
             </div>
             </div>
            </section>  
 
            <section className='our-projects'>
     <div className='container-fluid'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='fields-text'>
                <h2 className='Offering'>We specialise in every aspect of internal <br /> <span className='light'>and external building projects</span></h2>
            <SimilarProjects />
                </div>
             
            </div>
        </div>
    </div>
</section>
   <section className='benefits'>
    <div className='container'>
        <div className='row' id='benerow'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12'>
                <h2>Your Vision, Our Passion:<span className="lightspan">Why Choose Us</span></h2>
                <p className='underwhelm'>Discover why our exceptional expertise and dedication set us apart, ensuring your project's success and exceeding your expectations.</p>
            </div>
        </div>
        <div className='row' id='inexpensive'>
         <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12' >
          <img src={constructioncenter} className='construction-center' alt='' />
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12' >
            <div className='Exteriorbox2'>
             <p className='Competently'><span className='number'> 1.</span>Expertise: Extensive industry knowledge and experience ensure precise execution of construction projects, meeting highest standards and exceeding expectations consistently.</p>
            <hr />
            <p className='Competently'><span className='number'> 2.</span>Quality: From materials to workmanship, our commitment to excellence guarantees superior results, reflecting durability, functionality, and aesthetic appeal.</p>
            <hr />
            <p className='Competently'><span className='number'> 3.</span>Dedication: We are passionate about every project, striving to achieve client satisfaction through unwavering dedication, attention to detail, and proactive problem-solving.</p>
            </div>
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12' >
            <div className='Exteriorbox2'>
             <p className='Competently'><span className='number'> 4.</span>Innovation: Embracing modern techniques and technologies, we offer innovative solutions tailored to each project's unique requirements, ensuring optimal efficiency and effectiveness.</p>
            <hr />
            <p className='Competently'><span className='number'> 5.</span>Communication: Transparent communication is key to our approach, fostering strong client relationships and ensuring clarity, accountability, and alignment throughout the project lifecycle.</p>
            <hr />
            <p className='Competently'><span className='number'> 6.</span>Track Record: With a history of successful projects and satisfied clients, we have earned a reputation for reliability, integrity, and exceptional service delivery.</p>
            </div>
            </div>
            </div>        
        </div>

</section>
<hr />
<section className='innercta'>
  <div className='container'>
    <div className='row'>
      <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12'>
        <div className='cta-text'>
          <h3>Wanna Work With Our Profesional Team? <span className='lightspan'>Make an Appointment.</span></h3>
         
        </div>
      </div>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
      
      <div className="button float-right">
      <Queryform />
</div>
      </div>
    </div>
  </div>
</section>  
      <FooterThree className="" mainClassName="fix" />
    </>
   
  );
};

export default NewBuilds;
