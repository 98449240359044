import React from "react";
import drivewaybnr from "../../src/assets/image/drivewaybnr.webp";
import driveside from "../../src/assets/image/driveside.webp";
import lighthousebg from "../../src/assets/image/lighthousebg.png";
import road from "../../src/assets/image/road.png";
import DrivewayRepair from "../../src/assets/image/DrivewayRepair.png";
import stamp from "../../src/assets/image/stamp.png";
import drive from "../../src/assets/image/drive.png";
import SimilarProjects from "../components/ServicesArea/SimilarProjects";
import Queryform from "../components/Queryform";
import drivelow from "../../src/assets/image/drivelow.webp";
import HeaderTwo from "../components/Headers/HeaderTwo";
import FooterThree from "../components/Footers/FooterThree";
import {Helmet} from "react-helmet";
import Preloader from "../components/Preloader/Preloader";
import ScrollTop from "../components/ScrollTop/ScrollTop";

const Driveways = () => {
  return (
    <>
    <Preloader />
    <ScrollTop />
      <HeaderTwo className="" mainClassName="fix" />
      <Helmet>
  <meta charSet="utf-8" />
  <title>Pave the Way: Expert Driveway Construction Solutions for Your Home</title>
  </Helmet>
      <section className="section image-infinite-bg position-relative animation-bg"
       style={{
      backgroundImage: "url(" + drivewaybnr + ")", backgroundSize: "cover",backgroundRepeat: "no-repeat",backgroundPosition: "center", padding: "15em 1em",}} >

    <div className="r-container h-100">
      <div className="image-overlay" />
      <div
        className="d-flex flex-column justify-content-center align-items-center text-center gap-3 h-100 position-relative"
        style={{ zIndex: 2 }} > 
        <h1 className="text-title text-white bnnerheading">Expert Driveway <br /><span className='lightspan'>Construction Services</span></h1>
          <div className="d-flex flex-row gap-3">
          <div class="bnnerbtn">
           <a class="site-button btn-half" href="tel:07710 519713"><span>Contact us now</span></a>
           </div>
          
         
        </div>
      </div>
    </div>
  </section>
  <section className='interior-center'>
    <div className='container'>
      <div className='row'>
       <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
         <div className='sidevideo'>
         <img src={driveside} className='converstion1' alt='converstion1' />
          </div>
             </div>
               <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <h2 className='experienced'> Paving Your Path: Driveway
                   <span className='lightspan'> Construction Experts</span></h2>
                     <p className='visualize'>Our seasoned crew meticulously crafts driveways, ensuring longevity and aesthetic appeal. Trust us for unparalleled professionalism and lasting results.</p>
                     <p className='visualize'>From concept to completion, our driveway solutions blend seamlessly with your property's design, enhancing its overall allure and value.</p>
                       <div className='row'>
                         <div className='col-xl-6 col-xl-6 col-lg-12 col-md-12 col-12'>
                            <ul  className="list-angle-right anchor-line retail">
                              <li>Enhanced Curb Appeal</li>
                                <li>Increased Property Value</li>
                                 <li>Improved Accessibility</li>
                                  <li>Durability</li>
                                   </ul>           
                                </div>
                                <div className='col-xl-6 col-xl-6 col-lg-12 col-md-12 col-12' id='Projects'>
                                  <ul  className="list-angle-right anchor-line retail">
                                    <li>Safety</li>
                                    <li>Efficient Drainage</li>
                                    <li>Personalisation Options</li>
                                     <li>Environmental Benefits</li>
                                     </ul>           
                                </div>
                             </div>
                            
                              </div>
                        </div>
                    </div>
     </section>           
     <section className='inner-serivces'>
 <img src={lighthousebg} className='lighthousebg' alt='' />
    <div className='container'>
        <div className='row' id='dynmic'>
            <div className='col-xxl-7 col-xl-lg-7 col-md-12 col-12'>
                <h2>We excel in creating<span className='lightspan'> smooth driveways</span></h2>
                <p className='visualize'>Our construction services excel in creating smooth driveways, enhancing properties with expert craftsmanship, durability, and  personalised design options.</p>
            </div>
        </div>
        <div className='row' id='two'>
         <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
          <img src={road} className='commcail' alt='' />
          <h3 className='Commercial'>Driveway Installation</h3>
            <p>Expertly construct durable driveways tailored to your property's design, ensuring longevity and functionality.</p>
            </div>
           <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={DrivewayRepair} className='commcail' alt='' />
            <h3 className='Commercial'>Driveway Repair </h3>
            <p>Address potholes, cracks, and surface damage promptly with our professional repair services, restoring your driveway's integrity.</p>
             </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={stamp} className='commcail' alt='' />
            <h3 className='Commercial'>Driveway Sealing</h3>
            <p>Protect your investment with our sealing services, extending the lifespan of your driveway while enhancing its appearance.</p>
             </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={drive} className='commcail' alt='' />
            <h3 className='Commercial'>Driveway Resurfacing </h3>
            <p>Revitalize worn-out driveways with our resurfacing solutions, giving them a fresh look and renewed structural integrity.</p>
             </div>
             </div>
             </div>
            </section>  
      
    <section className='our-projects'>
     <div className='container-fluid'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='fields-text'>
                <h2 className='Offering'>We specialise in every aspect of internal <br /> <span className='light'>and external building projects</span></h2>
            <SimilarProjects />
                </div>
             
            </div>
        </div>
    </div>
</section>
   <section className='benefits'>
    <div className='container'>
        <div className='row' id='benerow'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12'>
                <h2>Experience the Difference <span className="lightspan">with Us</span></h2>
                <p className='underwhelm'>Discover unparalleled quality, reliability, and customer satisfaction when<br /> you choose our services. Experience excellence with us today!</p>
            </div>
        </div>
        <div className='row' id='inexpensive'>
         <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12' >
          <img src={drivelow} className='construction-center' alt='' />
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12' >
            <div className='Exteriorbox'>
             <p className='Competently'><span className='number'> 1.</span>Exceptional Quality: We prioritize top-notch materials and craftsmanship in every project we undertake, ensuring long-lasting results.</p>
            <hr />
            <p className='Competently'><span className='number'> 2.</span>Customer-Centric Approach: Your satisfaction is our priority, and we strive to exceed your expectations through attentive service and clear communication.</p>
            <hr />
            <p className='Competently'><span className='number'> 3.</span>Experienced Team: Our skilled professionals bring years of expertise to the table, guaranteeing precision and efficiency in every aspect of our work.</p>
            </div>
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12' >
            <div className='Exteriorbox'>
             <p className='Competently'><span className='number'> 4.</span>Competitive Pricing: We offer competitive rates without compromising on quality, providing excellent value for your investment.</p>
            <hr />
            <p className='Competently'><span className='number'> 5.</span>Timely Completion: With efficient project management and a commitment to deadlines, we ensure your project is completed on schedule.</p>
            <hr />
            <p className='Competently'><span className='number'> 6.</span>Customised Solutions: We tailor our services to your specific needs and preferences, delivering  personalised solutions that suit your unique requirements.
</p>
            </div>
            </div>
            </div>        
        </div>

</section>
<hr />
<section className='innercta'>
  <div className='container'>
    <div className='row'>
      <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12'>
        <div className='cta-text'>
          <h3>Wanna Work With Our Profesional Team? <span className='lightspan'>Make an Appointment.</span></h3>
         
        </div>
      </div>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
      
      <div className="button float-right">
      <Queryform />
</div>
      </div>
    </div>
  </div>
</section>

      <FooterThree className="" mainClassName="fix" />
    </>
   
  );
};

export default Driveways;
