import React from "react";
import renovatiobnr from "../../src/assets/image/renovatiobnr.webp";
import renovatioside from "../../src/assets/image/renovatioside.webp";
import lighthousebg from "../../src/assets/image/lighthousebg.png";
import kitchen from "../../src/assets/image/kitchen.png";
import shower from "../../src/assets/image/shower.png";
import basement from "../../src/assets/image/basement.png";
import Outbuilding from "../../src/assets/image/Outbuilding.png";
import SimilarProjects from "../components/ServicesArea/SimilarProjects";
import Queryform from "../components/Queryform";
import renolow from "../../src/assets/image/renolow.webp";
import HeaderTwo from "../components/Headers/HeaderTwo";
import FooterThree from "../components/Footers/FooterThree";
import {Helmet} from "react-helmet";
import Preloader from "../components/Preloader/Preloader";
import ScrollTop from "../components/ScrollTop/ScrollTop";

const Renovation = () => {
  
  return (
    <>
    <Preloader />
    <ScrollTop />
      <HeaderTwo className="" mainClassName="fix" />
      <Helmet>
  <meta charSet="utf-8" />
  <title>Revitalize Your Home: Expert Renovation Solutions for Every Space</title>
  </Helmet>
      <section className="section image-infinite-bg position-relative animation-bg"
       style={{
      backgroundImage: "url(" + renovatiobnr + ")", backgroundSize: "cover",backgroundRepeat: "no-repeat",backgroundPosition: "center", padding: "15em 1em",}} >

    <div className="r-container h-100">
      <div className="image-overlay" />
      <div
        className="d-flex flex-column justify-content-center align-items-center text-center gap-3 h-100 position-relative"
        style={{ zIndex: 2 }} >
        <h1 className="text-title text-white bnnerheading">Home Renovation <br /><span className='lightspan'>and Refurbishment </span></h1>
          <div className="d-flex flex-row gap-3">
          <div class="bnnerbtn">
           <a class="site-button btn-half" href="tel:07710 519713"><span>Contact us now</span></a>
           </div>
          
         
        </div>
      </div>
    </div>
  </section>
  <section className='interior-center'>
    <div className='container'>
      <div className='row'>
       <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
         <div className='sidevideo'>
         <img src={renovatioside} className='converstion1' alt='converstion1' />
          </div>
             </div>
               <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>  
                 <h2 className='experienced'> Revamp Your Space With
                   <span className='lightspan'> Top-notch Renovation Services  </span></h2>
                     <p className='visualize'>Ready to transform your living space? We specialise  in bringing your renovation dreams to life. Let's build together, crafting a space that reflects your unique style and needs.</p>
                     <p className='visualize'>From the initial concept to the final finishing touches, our dedicated team will be with you every step of the way. Experience the joy of a revitalised  home as we guide you through the renovation journey with expertise and care.</p>
                     <p className='visualize'>Don't wait any longer to create the home you've always dreamed of. Contact us today and let's embark on this exciting journey together!</p>
                       <div className='row'>
                         <div className='col-xl-6 col-xl-6 col-lg-12 col-md-12 col-12'>
                            <ul  className="list-angle-right anchor-line retail">
                              <li>Functionality boost</li>
                                <li>Higher property value</li>
                                 <li>Customized design</li>
                                  <li>Energy savings</li>
                                   </ul>           
                                </div>
                                <div className='col-xl-6 col-xl-6 col-lg-12 col-md-12 col-12' id='Projects'>
                                  <ul  className="list-angle-right anchor-line retail">
                                    <li>Increased comfort</li>
                                    <li>Improved aesthetics</li>
                                    <li>Enhanced safety</li>
                                     <li>Long-term investment</li>
                                     </ul>           
                                </div>
                             </div>
                            
                              </div>
                        </div>
                    </div>
     </section>           
     <section className='inner-serivces'>
 <img src={lighthousebg} className='lighthousebg' alt='' />
    <div className='container'>
        <div className='row' id='dynmic'>
            <div className='col-xxl-7 col-xl-lg-7 col-md-12 col-12'>
                <h2>Transforming Ideas into <span className='lightspan'>Stunning Renovation Artwork</span></h2>
                <p className='visualize'>Experience the alchemy of renovation as we craft your ideas into artistic masterpieces that redefine your living environment.</p>
            </div>
        </div>
        <div className='row' id='two'>
         <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
          <img src={kitchen} className='commcail' alt='' />
          <h3 className='Commercial'>Kitchen Remodelling </h3>
            <p>Revitalised  your kitchen with our expert remodelling services, tailored to enhance functionality and aesthetic appeal, perfecting your culinary space</p>
            </div>
           <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
           <img src={shower} className='commcail' alt='' />
            <h3 className='Commercial'>Bathroom Renovation</h3>
            <p>Indulge in luxury and comfort with our bespoke bathroom renovations, combining functionality with elegance for your personal sanctuary</p>
             </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
           
            <img src={basement} className='commcail' alt='' />
            <h3 className='Commercial'>Basement Finishing</h3>
            <p>Unlock the potential of your basement with our finishing services, transforming it into a versatile and welcoming extension of your home</p>
             </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={Outbuilding} className='commcail' alt='' />
            <h3 className='Commercial'>Design Revamp</h3>
            <p>Elevate your interior design with our transformative services, tailored to reflect your style and create harmonious living spaces</p>
             </div>
             </div>
             </div>
            </section>  
 
            <section className='our-projects'>
     <div className='container-fluid'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='fields-text'>
                <h2 className='Offering'>We specialise in every aspect of internal <br /> <span className='light'>and external building projects</span></h2>
            <SimilarProjects />
                </div>
             
            </div>
        </div>
    </div>
</section>
   <section className='benefits'>
    <div className='container'>
        <div className='row' id='benerow'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12'>
                <h2>Why Choose Our <span className="lightspan">Renovation Services?</span></h2>
                <p className='underwhelm'>Discover why our renovation services stand out: expert craftsmanship, attention to detail, <br />personalized designs, and a commitment to excellence.</p>
            </div>
        </div>
        <div className='row' id='inexpensive'>
         <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12' >
          <img src={renolow} className='construction-center' alt='' />
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12' >
            <div className='Exteriorbox'>
             <p className='Competently'><span className='number'> 1.</span>Expert Craftsmanship: Our skilled professionals ensure top-quality workmanship in every project, delivering lasting results.</p>
            <hr />
            <p className='Competently'><span className='number'> 2.</span>Attention to Detail: We meticulously attend to every aspect of your renovation, ensuring nothing is overlooked.</p>
            <hr />
            <p className='Competently'><span className='number'> 3.</span>Personalized Designs: Tailoring our approach to your unique preferences and needs, we bring your vision to life.</p>
            </div>
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12' >
            <div className='Exteriorbox'>
             <p className='Competently'><span className='number'> 4.</span>Commitment to Excellence: Our dedication to perfection means we go above and beyond to exceed your expectations.</p>
            <hr />
            <p className='Competently'><span className='number'> 5.</span>Transparent Communication: We keep you informed and involved throughout the process, fostering trust and satisfaction.</p>
            <hr />
            <p className='Competently'><span className='number'> 6.</span>Timely Completion: With efficient project management, we strive to deliver your renovation on time and within budget, minimizing disruptions.</p>
            </div>
            </div>
            </div>        
        </div>

</section>
<hr />
<section className='innercta'>
  <div className='container'>
    <div className='row'>
      <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12'>
        <div className='cta-text'>
          <h3>Wanna Work With Our Profesional Team? <span className='lightspan'>Make an Appointment.</span></h3>
         
        </div>
      </div>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
      
      <div className="button float-right">
      <Queryform />
</div>
      </div>
    </div>
  </div>
</section>  
   
   
      <FooterThree className="" mainClassName="fix"/>
    </>
  
  );
};

export default Renovation;
