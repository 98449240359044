import React from "react";
import Layout from "../layouts/Layout";
import Loft from "../../src/assets/image/Loft.webp";
import loftside from "../../src/assets/image/loftside.webp";
import lighthousebg from "../../src/assets/image/lighthousebg.png";
import BespokeLoft from "../../src/assets/image/BespokeLoft.png"
import Structural from "../../src/assets/image/Structural.png"
import apartment from "../../src/assets/image/apartment.png"
import Outbuilding from "../../src/assets/image/Outbuilding.png";
import SimilarProjects from "../components/ServicesArea/SimilarProjects";
import Queryform from "../components/Queryform";
import loftlow from "../../src/assets/image/loftlow.webp";
import HeaderTwo from "../components/Headers/HeaderTwo";
import FooterThree from "../components/Footers/FooterThree";
import {Helmet} from "react-helmet";
import Preloader from "../components/Preloader/Preloader";
import ScrollTop from "../components/ScrollTop/ScrollTop";


const LoftConversions = () => {
  return (
    <>
    <Preloader />
    <ScrollTop />
      <HeaderTwo className="" mainClassName="fix" />
      <Helmet>
  <meta charSet="utf-8" />
  <title>Transform Your Space: Expert Loft Conversions for Modern Living</title>
  </Helmet>
      <section className="section image-infinite-bg position-relative animation-bg"
       style={{
      backgroundImage: "url(" + Loft + ")", backgroundSize: "cover",backgroundRepeat: "no-repeat",backgroundPosition: "center", padding: "15em 1em",}} >

    <div className="r-container h-100">
      <div className="image-overlay" />
      <div
        className="d-flex flex-column justify-content-center align-items-center text-center gap-3 h-100 position-relative"
        style={{ zIndex: 2 }} >
        <h1 className="text-title text-white bnnerheading">Customised Loft <br /><span className='lightspan'>Conversion Services</span></h1>
          <div className="d-flex flex-row gap-3">
          <div class="bnnerbtn">
           <a class="site-button btn-half" href="tel:07710 519713"><span>Contact us now</span></a>
           </div>
          
         
        </div>
      </div>
    </div>
  </section>

  <section className='interior-center'>
    <div className='container'>
      <div className='row'>
       <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
         <div className='sidevideo'>
         <img src={loftside} className='converstion1' alt='converstion1' />
          </div>
             </div>
               <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <h2 className='experienced'> Unlock Your Home's Potential
                   <span className='lightspan'>  with Loft Conversions</span></h2>
                     <p className='visualize'>Discover the artistry behind loft conversions, where unused spaces are transformed into functional sanctuaries. Whether you envision a cosy retreat for relaxation or a spacious studio for creativity, the possibilities are endless.</p>
                     <p className='visualize'>With tailored designs that seamlessly blend with your lifestyle, loft conversions offer a unique opportunity to enhance living standards and add significant value to your property. Embrace innovation and creativity as you reimagine your home, turning previously neglected areas into vibrant hubs of activity and comfort. Discover the transformative power of loft conversions:</p>
                       <div className='row'>
                         <div className='col-xl-6 col-xl-6 col-lg-12 col-md-12 col-12'>
                            <ul  className="list-angle-right anchor-line retail">
                              <li>Customisable designs</li>
                                <li>Cost-effective solution</li>
                                 <li>Energy-efficient upgrades</li>
                                  <li>Elevated living experience</li>
                                   </ul>           
                                </div>
                                <div className='col-xl-6 col-xl-6 col-lg-12 col-md-12 col-12' id='Projects'>
                                  <ul  className="list-angle-right anchor-line retail">
                                    <li>Space optimization</li>
                                    <li>Property value boost</li>
                                    <li>Versatile functionality</li>
                                     <li>Enhanced light and air</li>
                                     </ul>           
                                </div>
                             </div>
                            
                              </div>
                        </div>
                    </div>
     </section>           
     <section className='inner-serivces'>
 <img src={lighthousebg} className='lighthousebg' alt='' />
    <div className='container'>
        <div className='row' id='dynmic'>
            <div className='col-xxl-8 col-xl-lg-8 col-md-12 col-12'>
                <h2>From Ideas to Art: Our <span className='lightspan'>Masterful Transformations</span></h2>
                <p className='visualize'>
                We excel in transforming your ideas into stunning works of art. From initial concept to final execution, we are dedicated to creating spaces that are truly extraordinary. With a focus on quality, creativity, and innovation, we take pride in delivering exceptional results that surpass your expectations.
                </p>
            </div>
        </div>
        <div className='row' id='two'>
         <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
          <img src={BespokeLoft} className='commcail' alt='' />
          <h3 className='Commercial'>Bespoke Loft Design</h3>
            <p>
            Crafted to your specifications, our designs maximise  space and style, ensuring a tailored loft that reflects your personality.
            </p>
            </div>
           <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={Structural} className='commcail' alt='' />
            <h3 className='Commercial'>Structural Alterations</h3>
            <p>Expertly executed structural changes guarantee compliance with safety regulations, providing a secure foundation for your loft conversion.</p>
             </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={apartment} className='commcail' alt='' />
            <h3 className='Commercial'>Skylight Installation</h3>
            <p>Elevate your loft with professionally installed skylights, optimizing natural light and ventilation while enhancing energy efficiency and ambiance.</p>
             </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={Outbuilding} className='commcail' alt='' />
            <h3 className='Commercial'>Full Project Management</h3>
            <p>From concept to completion, we oversee every detail, ensuring a smooth, stress-free experience and delivering exceptional results within your timeline and budget.</p>
             </div>
             </div>
             </div>
            </section>  
     
            <section className='our-projects'>
     <div className='container-fluid'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='fields-text'>
                <h2 className='Offering'>We specialise in every aspect of internal <br /> <span className='light'>and external building projects</span></h2>
            <SimilarProjects />
                </div>
             
            </div>
        </div>
    </div>
</section>
   <section className='benefits'>
    <div className='container'>
        <div className='row' id='benerow'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12'>
                <h2>Choose Our <span className="lightspan">Conversion Expertise</span></h2>
                <p className='underwhelm'>Discover the difference with us – your premier choice for loft conversions,<br /> where expertise meets creativity to transform your space into something extraordinary. </p>
            </div>
        </div>
        <div className='row' id='inexpensive'>
         <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12' >
          <img src={loftlow} className='construction-center' alt='' />
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12' >
            <div className='Exteriorbox'>
             <p className='Competently'><span className='number'> 1.</span>Expertise: Benefit from our extensive industry experience and knowledge for a seamless and efficient loft conversion process.</p>
            <hr />
            <p className='Competently'><span className='number'> 2.</span>Tailored Solutions: Receive personalized service tailored to your unique vision and requirements, ensuring a perfect fit for your space.</p>
            <hr />
            <p className='Competently'><span className='number'> 3.</span>Quality Craftsmanship: Trust our skilled craftsmen to utilize top-tier materials and techniques, guaranteeing durable and high-quality results.</p>
            </div>
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12' >
            <div className='Exteriorbox'>
             <p className='Competently'><span className='number'> 4.</span>Transparent Communication: Stay fully informed with clear and open communication, keeping you updated on project progress and decisions.</p>
            <hr />
            <p className='Competently'><span className='number'> 5.</span>Attention to Detail: Experience meticulous attention to detail in every aspect of your loft conversion, ensuring a flawless finish.</p>
            <hr />
            <p className='Competently'><span className='number'> 6.</span>Customer Satisfaction: Your happiness is our priority; we're dedicated to delivering exceptional service and support throughout your project.</p>
            </div>
            </div>
            </div>        
        </div>

</section>
<hr />
<section className='innercta'>
  <div className='container'>
    <div className='row'>
      <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12'>
        <div className='cta-text'>
        <h3>Want to Discuss Your Construction Needs <span className='lightspan'>With Us? Schedule A Consultation Today.</span></h3>
         
        </div>
      </div>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
      
      <div className="button float-right">
      <Queryform />
</div>
      </div>
    </div>
  </div>
</section>  
   
   
      <FooterThree className="" mainClassName="fix" />
    </>
  
  );
};

export default LoftConversions;
