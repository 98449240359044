import React from "react";
import Layout from "../layouts/Layout";



const Privacypolicy = () => {
  return (
    <Layout header={1} footer={3} className="" mainClassName="fix">
    <section className="privacybnr" >
        <div className="container">
            <div className="row">
            <div className="col-xxl-12 col-12">
              <h2>Privacy Policy</h2>
            </div>
               
            </div>
        </div>
     </section>
      
 <section className="privacy-content">
    <div className="container">
        <div className="row">
        <div className="col-xxl-12 col-12">
        <div className="consent">
         <h2 className="prolu"> Privacy Policy</h2>
          <p className="safeguarding">
          At NexaBuild Construction, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide to us through our website, services, and interactions with our company.
          </p>
          <h3>Information We Collect:</h3>
           <p className="bold-t">Personal Information:</p>
          <p className="safeguarding">
          When you use our services or interact with our website, we may collect personal information such as your name, email address, phone number, and address.
          </p>
         <p className="bold-t">Usage Information: </p>
         <p className="safeguarding">
         We may collect information about how you use our website, including your IP address, browser type, pages visited, and other usage statistics.
          </p>

          <p className="bold-t">Providing Services:  </p>
         <p className="safeguarding">We use your information to provide you with the services you request, such as construction, interior design, and exterior design services.</p>
         <p className="bold-t">Communication: </p>
         <p className="safeguarding">We may use your contact information to communicate with you about our services, promotions, and updates.</p>
         <p className="bold-t">Improving Our Services:  </p>
         <p className="safeguarding">We analyze usage data to improve our website, services, and customer experience.</p>
        <h3>Disclosure of Information:</h3>
        <p className="bold-t">Third-Party Service Providers: </p>
        <p className="safeguarding">We may share your personal information with third parties who assist us in providing services, such as suppliers and subcontractors. </p>
        <p className="bold-t">Legal Requirements: </p>
        <p className="safeguarding">We may disclose your information in response to legal requests or to comply with applicable laws and regulations.</p>
        <h3>Data Security:</h3>
        <p className="safeguarding">
        We take appropriate measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. These measures include encryption, secure data storage, and regular security assessments.
        </p>
        <h3>Your Choices:</h3>
        <p className="safeguarding">
        You have the right to access, update, or delete your personal information. You may also choose to opt out of receiving promotional communications from us.
        </p>
        <h3>Children's Privacy:</h3>
        <p className="safeguarding">
        Our services are not directed to children under the age of 13, and we do not knowingly collect personal information from children.
        </p>
        <h3>Changes to this Privacy Policy:</h3>
        <p className="safeguarding">
        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
        </p>
         <p className="bold-t">Contact us   </p>
            <p className="safeguarding">If you have any questions or queries about our privacy policy, feel free to contact us at <strong><a href="info@nexabuild-construction.co.uk">info@nexabuild-construction.co.uk.</a></strong>. 
            </p>

          </div>   
          </div>
        </div>
    </div>
 </section>
   
    </Layout>
  );
};

export default Privacypolicy;
