import React from "react";
import kitchenbnr from "../../src/assets/image/kitchenbnr.webp";
import kitchenside from "../../src/assets/image/kitchenside.webp";
import lighthousebg from "../../src/assets/image/lighthousebg.png";
import kitchen from "../../src/assets/image/kitchen.png";
import kitcheninstall from "../../src/assets/image/kitcheninstall.png";
import makeover from "../../src/assets/image/makeover.png";
import wrench from "../../src/assets/image/wrench.png";
import SimilarProjects from "../components/ServicesArea/SimilarProjects";
import Queryform from "../components/Queryform";
import kitchenlow from "../../src/assets/image/kitchenlow.webp";
import HeaderTwo from "../components/Headers/HeaderTwo";
import FooterThree from "../components/Footers/FooterThree";
import {Helmet} from "react-helmet";
import Preloader from "../components/Preloader/Preloader";
import ScrollTop from "../components/ScrollTop/ScrollTop";


const Kitchen = () => {
  return (
    <>
    <Preloader />
    <ScrollTop />
      <HeaderTwo className="" mainClassName="fix" />
      <Helmet>
  <meta charSet="utf-8" />
  <title>Crafting Culinary Spaces: Expert Kitchen Construction Solutions</title>
  </Helmet>
      <section className="section image-infinite-bg position-relative animation-bg"
       style={{
      backgroundImage: "url(" + kitchenbnr + ")", backgroundSize: "cover",backgroundRepeat: "no-repeat",backgroundPosition: "center", padding: "15em 1em",}} >

    <div className="r-container h-100">
      <div className="image-overlay" />
      <div
        className="d-flex flex-column justify-content-center align-items-center text-center gap-3 h-100 position-relative"
        style={{ zIndex: 2 }} >
        <h1 className="text-title text-white bnnerheading">Nitchen Construction <br /><span className='lightspan'>Services </span></h1>
          <div className="d-flex flex-row gap-3">
          <div class="bnnerbtn">
           <a class="site-button btn-half" href="tel:07710 519713"><span>Contact us now</span></a>
           </div>
          
         
        </div>
      </div>
    </div>
  </section>
  <section className='interior-center'>
    <div className='container'>
      <div className='row'>
       <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
         <div className='sidevideo'>
         <img src={kitchenside} className='converstion1' alt='converstion1' />
          </div>
             </div>
               <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <h2 className='experienced'> Quality craftsmanship and affordable 
                   <span className='lightspan'> solutions for your dream kitchen.</span></h2>
                     <p className='visualize'>At NexaBuild Construction, we specialise in refurbishing and extending kitchens to create stunning and fully functional spaces. </p>
                     <p className='visualize'>Whether you are looking for a modern, sleek, mid-century style kitchen or anything else, our dedicated team is an expert in creating stunning kitchens tailored to your unique needs.</p>
                       <div className='row'>
                         <div className='col-xl-6 col-xl-6 col-lg-12 col-md-12 col-12'>
                            <ul  className="list-angle-right anchor-line retail">
                              <li>Long-lasting durability</li>
                                <li>Improved functionality</li>
                                 <li>Enhanced aesthetics</li>
                                  <li>High-quality materials</li>
                                   </ul>           
                                </div>
                                <div className='col-xl-6 col-xl-6 col-lg-12 col-md-12 col-12' id='Projects'>
                                  <ul  className="list-angle-right anchor-line retail">
                                    <li>Customisation options</li>
                                    <li>Increased property value</li>
                                    <li>Improved safety standards</li>
                                     <li>Enhanced lifestyle satisfaction</li>
                                     </ul>           
                                </div>
                             </div>
                            
                              </div>
                        </div>
                    </div>
     </section>           
     <section className='inner-serivces'>
 <img src={lighthousebg} className='lighthousebg' alt='' />
    <div className='container'>
        <div className='row' id='dynmic'>
            <div className='col-xxl-7 col-xl-lg-7 col-md-12 col-12'>
                <h2>Transforming Kitchens: Tailored <span className='lightspan'>Construction Solutions Await</span></h2>
                <p className='visualize'>Transform your kitchen dreams into reality with our range of specialise construction services, tailored to your unique vision and requirements.</p>
            </div>
        </div>
        <div className='row' id='two'>
         <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
          <img src={kitchen} className='commcail' alt='' />
          <h3 className='Commercial'>Design consultation</h3>
            <p>Collaborating with clients to create customized kitchen layouts and plans tailored to their needs and preferences.</p>
            </div>
           <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={kitcheninstall} className='commcail' alt='' />
            <h3 className='Commercial'>Installation services </h3>
            <p>Expert installation of cabinetry, countertops, appliances, and fixtures to ensure precise fit and functionality.</p>
             </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={makeover} className='commcail' alt='' />
            <h3 className='Commercial'>Remodeling & renovation</h3>
            <p>Transforming outdated or inefficient kitchens into modern, stylish spaces through comprehensive remodeling services.</p>
             </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={wrench} className='commcail' alt='' />
            <h3 className='Commercial'>Maintenance & repair</h3>
            <p>Providing ongoing maintenance services and prompt repairs to ensure the longevity and optimal performance of kitchen components.</p>
             </div>
             </div>
             </div>
            </section>  
   
            <section className='our-projects'>
     <div className='container-fluid'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='fields-text'>
                <h2 className='Offering'>We specialise in every aspect of internal <br /> <span className='light'>and external building projects</span></h2>
            <SimilarProjects />
                </div>
             
            </div>
        </div>
    </div>
</section>
   <section className='benefits'>
    <div className='container'>
        <div className='row' id='benerow'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12'>
                <h2>Why choose us for your kitchen <br /><span className="lightspan">construction needs? </span></h2>
                <p className='underwhelm'>Discover why we're your top choice for kitchen construction—quality craftsmanship, <br />personalized service, and unparalleled expertise await you.</p>
            </div>
        </div>
        <div className='row' id='inexpensive'>
         <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12' >
          <img src={kitchenlow} className='construction-center' alt='' />
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12' >
            <div className='Exteriorbox'>
             <p className='Competently'><span className='number'> 1.</span>Quality Craftsmanship: Our expertise ensures your kitchen is built with durability and aesthetics at the forefront of construction.</p>
            <hr />
            <p className='Competently'><span className='number'> 2.</span>Personalized Service: We collaborate closely to understand your vision, offering tailored solutions to bring your dream kitchen to fruition.</p>
            <hr />
            <p className='Competently'><span className='number'> 3.</span>Unparalleled Expertise: Our team excels in all aspects of kitchen construction, from design to installation, ensuring exceptional results.</p>
            </div>
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12' >
            <div className='Exteriorbox'>
             <p className='Competently'><span className='number'> 4.</span>Attention to Detail: Every element is meticulously considered and flawlessly executed, reflecting our commitment to perfection in every project.</p>
            <hr />
            <p className='Competently'><span className='number'> 5.</span>Timely Completion: Efficient project management ensures your kitchen is completed on schedule,  minimising disruption to your daily routine.</p>
            <hr />
            <p className='Competently'><span className='number'> 6.</span>Customer Satisfaction: We  prioritise your happiness, delivering a kitchen that not only meets but exceeds your expectations for quality and service.</p>
            </div>
            </div>
            </div>        
        </div>

</section>
<hr />
<section className='innercta'>
  <div className='container'>
    <div className='row'>
      <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12'>
        <div className='cta-text'>
          <h3>Wanna Work With Our Profesional Team? <span className='lightspan'>Make an Appointment.</span></h3>
         
        </div>
      </div>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
      
      <div className="button float-right">
      <Queryform />
</div>
      </div>
    </div>
  </div>
</section>  
   
      <FooterThree className="" mainClassName="fix" />
    </>
  
  );
};

export default Kitchen;
