import React from "react";
import gallery1 from "../assets/image/gallery1.webp";
import gallery2 from "../assets/image/gallery2.webp";
import serpick3 from "../assets/image/serpick3.png";
import gallery3 from "../assets/image/gallery3.webp";
import gallery4 from "../assets/image/gallery4.webp";
import gallery5 from "../assets/image/gallery5.webp";
import ser5 from "../assets/image/ser5.webp";
import Aboutbnr from "../../src/assets/image/Aboutbnr.webp";
import gallery6 from "../assets/image/gallery6.webp";
import HeaderTwo from "../components/Headers/HeaderTwo";
import FooterThree from "../components/Footers/FooterThree";
import {Helmet} from "react-helmet";
import Preloader from "../components/Preloader/Preloader";
import ScrollTop from "../components/ScrollTop/ScrollTop";


const Gallery = () => {
  return (
    <>
    <Preloader />
    <ScrollTop />
      <HeaderTwo className="" mainClassName="fix" />
      <Helmet>
  <meta charSet="utf-8" />
  <title>Our Gallery</title>
  </Helmet>
      <section className="section image-infinite-bg position-relative animation-bg"
       style={{
      backgroundImage: "url(" + Aboutbnr + ")", backgroundSize: "cover",backgroundRepeat: "no-repeat",backgroundPosition: "center", padding: "15em 1em",}} >

    <div className="r-container h-100">
      <div className="image-overlay" />
      <div
        className="d-flex flex-column justify-content-center align-items-center text-center gap-3 h-100 position-relative"
        style={{ zIndex: 2 }} >
        <h1 className="text-title text-white bnnerheading">Our Construction  <br /><span className='lightspan'>Gallery </span></h1>
          <div className="d-flex flex-row gap-3">
          <div class="bnnerbtn">
           <a class="site-button btn-half" href="tel:07710 519713"><span>Contact us now</span></a>
           </div>
          
         
        </div>
      </div>
    </div>
  </section>
  <section className="ourgallery">
  <div className="container" id="collage">
  <div className="item-1">
  <img src={gallery1} alt="" className="img" />
  
    </div>
  <div className="item-2">
  <img src={gallery3} alt="" className="img" />
  </div>
  <div className="item-3">
  <img src={gallery2} alt="" className="img" />
  </div>
  <div className="item-4">
  <img src={gallery4} alt="" className="img" />
  </div>
  <div className="item-5">
  <img src={ser5} alt="" className="img" />
  </div>
  <div className="item-6">
  
  <img src={serpick3} alt="" className="img" />
  </div>
  <div className="item-7">
  <img src={gallery5} alt="" className="img" />
  </div>
  <div className="item-8">
  <img src={gallery6} alt="" className="img" />
  </div>
 
</div>
</section>
      <FooterThree className="" mainClassName="fix" />
    </>
    );
};

export default Gallery;
