import React from "react";
import houseexbnr from "../../src/assets/image/houseexbnr.webp";
import houseexside from "../../src/assets/image/houseexside.webp";
import lighthousebg from "../../src/assets/image/lighthousebg.png";
import talk from "../../src/assets/image/talk.png";
import commercail20 from "../../src/assets/image/commercail20.png";
import Management from "../../src/assets/image/Management.png";
import Outbuilding from "../../src/assets/image/Outbuilding.png";
import SimilarProjects from "../components/ServicesArea/SimilarProjects";
import Queryform from "../components/Queryform";
import constructioncenter from "../../src/assets/image/construction-center.png";
import HeaderTwo from "../components/Headers/HeaderTwo";
import FooterThree from "../components/Footers/FooterThree";
import {Helmet} from "react-helmet";
import Preloader from "../components/Preloader/Preloader";
import ScrollTop from "../components/ScrollTop/ScrollTop";

const HouseExtensions = () => {
  return (
    <>
    <Preloader />
    <ScrollTop />
      <HeaderTwo className="" mainClassName="fix" />
      <Helmet>
  <meta charSet="utf-8" />
  <title>Expand Your Space: Expert Home Extensions for Every Lifestyle</title>
  </Helmet>
      <section className="section image-infinite-bg position-relative animation-bg"
       style={{
      backgroundImage: "url(" + houseexbnr + ")", backgroundSize: "cover",backgroundRepeat: "no-repeat",backgroundPosition: "center", padding: "15em 1em",}} >

    <div className="r-container h-100">
      <div className="image-overlay" />
      <div
        className="d-flex flex-column justify-content-center align-items-center text-center gap-3 h-100 position-relative"
        style={{ zIndex: 2 }} >
        <h1 className="text-title text-white bnnerheading">Space Renewal <br /><span className='lightspan'>Construction Highlights</span></h1>
          <div className="d-flex flex-row gap-3">
          <div class="bnnerbtn">
           <a class="site-button btn-half" href="tel:07710 519713"><span>Contact us now</span></a>
           </div>
          
         
        </div>
      </div>
    </div>
  </section>
  <section className='interior-center'>
    <div className='container'>
      <div className='row'>
       <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
         <div className='sidevideo'>
         <img src={houseexside} className='converstion1' alt='converstion1' />
          </div>
             </div>
               <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <h2 className='experienced'> Expanding Homes: Craftsmanship
                   <span className='lightspan'> for Your Dream Addition</span></h2>
                     <p className='visualize'>Expanding your home offers more space for comfort and functionality. Our team ensures quality construction for your satisfaction.</p>
                     <p className='visualize'>With meticulous planning and expert craftsmanship, we transform your vision into a stunning reality. Trust us for your home extension needs.</p>
                       <div className='row'>
                         <div className='col-xl-6 col-xl-6 col-lg-12 col-md-12 col-12'>
                            <ul  className="list-angle-right anchor-line retail">
                              <li>Expanded Space</li>
                                <li>Increased Value</li>
                                 <li>Avoids Hassle</li>
                                  <li>Faster Completion</li>
                                   </ul>           
                                </div>
                                <div className='col-xl-6 col-xl-6 col-lg-12 col-md-12 col-12' id='Projects'>
                                  <ul  className="list-angle-right anchor-line retail">
                                    <li>Personalized Design</li>
                                    <li>Convenient Location</li>
                                    <li>Enhanced Functionality</li>
                                     <li>Sustainable Approach</li>
                                     </ul>           
                                </div>
                             </div>
                            
                              </div>
                        </div>
                    </div>
     </section>           
     <section className='inner-serivces'>
 <img src={lighthousebg} className='lighthousebg' alt='' />
    <div className='container'>
        <div className='row' id='dynmic'>
            <div className='col-xxl-7 col-xl-lg-7 col-md-12 col-12'>
                <h2>Home Extensions: Tailored Solutions<span className='lightspan'>for Every Space</span></h2>
                <p className='visualize'>Transform your home with our expert extension services. From design to construction, we ensure seamless integration and satisfaction with every project.</p>
            </div>
        </div>
        <div className='row' id='two'>
         <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
          <img src={talk} className='commcail' alt='' />
          <h3 className='Commercial'>Design Consultation </h3>
            <p>Collaborate with our experts to conceptualize your ideal extension, ensuring functionality, aesthetics, and compliance with building codes.</p>
            </div>
           <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={commercail20} className='commcail' alt='' />
            <h3 className='Commercial'>Structural Engineering</h3>
            <p>Our skilled engineers analyze the integrity of your home's structure and devise solutions to support seamless extensions.</p>
             </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={Management} className='commcail' alt='' />
            <h3 className='Commercial'>Construction Management</h3>
            <p>From permits to project completion, our team oversees every aspect, ensuring timely execution and adherence to quality standards.</p>
             </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={Outbuilding} className='commcail' alt='' />
            <h3 className='Commercial'>Interior Finishing  </h3>
            <p>Elevate your extension's ambiance with our meticulous attention to detail, offering exquisite finishes tailored to your taste and preferences.</p>
             </div>
             </div>
             </div>
            </section>  
           
    <section className='our-projects'>
     <div className='container-fluid'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='fields-text'>
                <h2 className='Offering'>We specialise in every aspect of internal <br /> <span className='light'>and external building projects</span></h2>
            <SimilarProjects />
                </div>
             
            </div>
        </div>
    </div>
</section>
   <section className='benefits'>
    <div className='container'>
        <div className='row' id='benerow'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12'>
                <h2>Experience Excellence <span className="lightspan">with Us</span></h2>
                <p className='underwhelm'>Choose us for unparalleled expertise, reliability, and dedication to turning your vision into reality with exceptional craftsmanship and service.</p>
            </div>
        </div>
        <div className='row' id='inexpensive'>
         <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12' >
          <img src={constructioncenter} className='construction-center' alt='' />
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12' >
            <div className='Exteriorbox'>
             <p className='Competently'><span className='number'> 1.</span>Expertise: Decades of industry experience ensure we navigate complexities efficiently, offering insights and solutions tailored to your project's needs.</p>
            <hr />
            <p className='Competently'><span className='number'> 2.</span>Reliability: Count on us for consistent, high-quality results, meeting deadlines and surpassing expectations through our proven track record.</p>
            <hr />
            <p className='Competently'><span className='number'> 3.</span>Dedication: Your vision is our priority. We devote ourselves to understanding your goals, bringing them to life with passion and precision.</p>
            </div>
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12' >
            <div className='Exteriorbox'>
             <p className='Competently'><span className='number'> 4.</span>Craftsmanship:  Our skilled artisans & tradespeople meticulously craft each element, ensuring exceptional quality & enduring beauty.</p>
            <hr />
            <p className='Competently'><span className='number'> 5.</span>Personalized Service: From initial consultation to project completion, we provide support, customizing our approach to your preferences & requirements.</p>
            <hr />
            <p className='Competently'><span className='number'> 6.</span>Customer Satisfaction: Your happiness is paramount. We strive for open communication, transparency, and exceeding your satisfaction at every stage of the process.</p>
            </div>
            </div>
            </div>        
        </div>

</section>
<hr />
<section className='innercta'>
  <div className='container'>
    <div className='row'>
      <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12'>
        <div className='cta-text'>
          <h3>Wanna Work With Our Profesional Team? <span className='lightspan'>Make an Appointment.</span></h3>
         
        </div>
      </div>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
      
      <div className="button float-right">
      <Queryform />
</div>
      </div>
    </div>
  </div>
</section>  
      <FooterThree className="" mainClassName="fix" />
    </>
  
  );
};

export default HouseExtensions;
