import React from "react";
import SlickSlider from "../SlickSlider/SlickSlider";

import dampbnr from "../../assets/image/dampbnr.webp";
import drivewaybnr from "../../assets/image/drivewaybnr.webp";
import houseexbnr from "../../assets/image/houseexbnr.webp";
import kitchenbnr from "../../assets/image/kitchenbnr.webp";
import Loft from "../../assets/image/Loft.webp";
import newbuild from "../../assets/image/newbuild.webp";
import renovatiobnr from "../../assets/image/renovatiobnr.webp";
import { useSlickArrows } from "../../lib/hooks/useSlickArrows";







const Allserivces = () => {

    const slick_settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        autoplay: true,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: true,
                },
              },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          },
        ],
      };
      const { sliderRef, toNext, toPrev } = useSlickArrows();
  return (
    <section className="project-area serivces-sec">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-4">
          <div className="project-content">
            <div className="section-title white-title mb-10">
              <h2>What<span className='lightspan'> we do </span></h2>
            </div>
            <p>
            We offer a vast spectrum of building services, from new-build projects to carefully planned renovations and custom extensions. 
            </p>
          
            <div className="content-bottom">
            <div class="text-0">
                 <a class="site-button btn-half" href="/About"><span>Read More</span></a>
                  </div>

                
            </div>
          </div>

        </div>

        <div className="col-xl-8">
          <div className="project-item-wrap allservices">
          <SlickSlider settings={slick_settings} >
          <div className="item">
             <div class="sx-post-media sx-img-effect img-reflection">
             <a class="" href="/New-Builds">
             <img src={newbuild} className='serpick1' alt='' />
             <div className='bottom-text'>
                <h3>New Builds</h3>
               <p>No matter what type of rebuild you’re looking for, we strive to provide you with a dream space on time and to your specifications. </p>
             </div>
             </a>
             </div>
            </div>
            <div className="item">
             <div class="sx-post-media sx-img-effect img-reflection">
             <a class="" href="/House-Extensions">
             <img src={houseexbnr} className='serpick1' alt='' />
             <div className='bottom-text'>
                <h3>House Extension</h3>
                <p> Whether it’s enlarging the existing area or adding a new space, you can trust us for the highest-quality extensions tailored to your needs. </p>
             </div>
             </a>
             </div>
            </div>
            <div className="item">
             <div class="sx-post-media sx-img-effect img-reflection">
             <a class="" href="/Renovation">
             <img src={renovatiobnr} className='serpick1' alt='' />
             <div className='bottom-text'>
                <h3>Renovation</h3>
                <p>Want to renovate your existing space? We will provide you with the highest standard renovation services to transform your property.</p>
             </div>
             </a>
             </div>
            </div>
            <div className="item">
             <div class="sx-post-media sx-img-effect img-reflection">
             <a class="" href="/Driveways">
             <img src={drivewaybnr} className='serpick1' alt='' />
             <div className='bottom-text'>
                <h3>Driveways</h3>
                <p>Ready to enhance your property? Discover the allure of our expert driveway construction, promising enduring charm and quality craftsmanship.</p>
             </div>
             </a>
             </div>
            </div>
           
            <div className="item">
             <div class="sx-post-media sx-img-effect img-reflection">
             <a class="" href="/Damp-Proofing">
             <img src={dampbnr} className='serpick1' alt='' />
             <div className='bottom-text'>
                <h3>Damp Proofing</h3>
                <p>Concerned about moisture damage? Trust our expert damp proofing solutions for lasting protection and peace of mind.</p>
             </div>
             </a>
             </div>
            </div>
            <div className="item">
             <div class="sx-post-media sx-img-effect img-reflection">
             <a class="" href="/Kitchen">
             <img src={kitchenbnr} className='serpick1' alt='' />
             <div className='bottom-text'>
                <h3>Kitchen </h3>
                <p>Elevate your kitchen with our bespoke designs, fusing functionality with personalized style. Trust our craftsmanship for a truly tailored experience.</p>
             </div>
             </a>
             </div>
            </div>
            <div className="item">
             <div class="sx-post-media sx-img-effect img-reflection">
             <a class="" href="/Loft-Conversions">
             <img src={Loft} className='serpick1' alt='' />
             <div className='bottom-text'>
                <h3>Loft Conversion</h3>
                <p>Considering expanding your home's space? Explore our expert loft conversions. From design to completion, we craft versatile spaces tailored to your needs.
                </p>
             </div>
             </a>
             </div>
            </div>
            
            </SlickSlider>
            <div className="inner-project-nav">
              <button type="button" className="slick-arrow" onClick={toPrev}>
                <i className="fal fa-long-arrow-left" />
              </button>
              <button type="button" className="slick-arrow" onClick={toNext}>
                <i className="fal fa-long-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </section>
  );
};

export default Allserivces;
