import React from "react";
import Layout from "../layouts/Layout";
import Aboutbnr from "../../src/assets/image/Aboutbnr.webp";
import aboutside from "../../src/assets/image/aboutside.png";
import talk from "../../src/assets/image/talk.png";
import sketch from "../../src/assets/image/sketch.png";
import assurance from "../../src/assets/image/assurance.png";
import Clientce from "../../src/assets/image/Clientce.png";
import Materials from "../../src/assets/image/Materials.png";
import craftsman from "../../src/assets/image/craftsman.png";
import lighthousebg from "../../src/assets/image/lighthousebg.png";
import basement from "../../src/assets/image/basement.png";
import Outbuilding from "../../src/assets/image/Outbuilding.png";
import SimilarProjects from "../components/ServicesArea/SimilarProjects";
import Queryform from "../components/Queryform";
import HeaderTwo from "../components/Headers/HeaderTwo";
import FooterThree from "../components/Footers/FooterThree";
import {Helmet} from "react-helmet";
import Preloader from "../components/Preloader/Preloader";
import ScrollTop from "../components/ScrollTop/ScrollTop";


const About = () => {
  return (
 <>
 <Preloader />
 <ScrollTop />
  <HeaderTwo className="" mainClassName="fix" />
  <Helmet>
  <meta charSet="utf-8" />
  <title>Building Your Dream Spaces</title>
  </Helmet>
  <section className="section image-infinite-bg position-relative animation-bg"
       style={{
      backgroundImage: "url(" + Aboutbnr + ")", backgroundSize: "cover",backgroundRepeat: "no-repeat",backgroundPosition: "center", padding: "15em 1em",}} >

    <div className="r-container h-100">
      <div className="image-overlay" />
      <div
        className="d-flex flex-column justify-content-center align-items-center text-center gap-3 h-100 position-relative"
        style={{ zIndex: 2 }} > 
        <h1 className="text-title text-white bnnerheading">About NexaBuild<br /><span className='lightspan'>Construction</span></h1>
          <div className="d-flex flex-row gap-3">
          <div class="bnnerbtn">
           <a class="site-button btn-half" href="tel:07710 519713"><span>Contact us now</span></a>
           </div>
                  
        </div>
      </div>
    </div>
  </section>

  <section className='interior-center'>
    <div className='container'>
      <div className='row'>
       <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
         <div className='sidevideo'>
         <img src={aboutside} className='converstion1' alt='converstion1' />
          </div>
             </div>
               <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
                 <h2 className='experienced'> Building Dreams: Crafting Quality
                   <span className='lightspan'> Structures with Expert Precision</span></h2>
                     <p className='visualize'>Our construction company blends craftsmanship with innovation, delivering excellence in every project. Our clients are our foremost priority. </p>
                     <p className='visualize'>With years of experience, we're dedicated to exceeding the expectations of our amiable clients and bringing their dream spaces to life. From meticulous planning to flawless execution, you can trust us to bring your vision to life with expertise. </p>
                     <div class="abtrbtn">
           <a class="site-button btn-half" href="tel:07710 519713"><span>Contact us</span></a>
           </div>
                            
                              </div>
                        </div>
                    </div>
     </section>           
     <section className='inner-serivces'>
 <img src={lighthousebg} className='lighthousebg' alt='' />
    <div className='container'>
        <div className='row' id='dynmic'>
            <div className='col-xxl-7 col-xl-lg-7 col-md-12 col-12'> 
                <h2>Discover Why We're Your <span className='lightspan'>Ideal Construction Partner</span></h2>
                <p className='visualize'>Discover the difference of unmatched construction with us: Through expert craftsmanship, seamless project coordination, client-centred service, and premium materials, we ensure your project surpasses expectations.
</p>
            </div>
        </div>
        <div className='row' id='two'>
         <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
          <img src={craftsman} className='commcail' alt='' />
          <h3 className='Commercial'>Expert Craftsmanship </h3>
            <p>Our team brings years of experience and skill to every project, ensuring top-quality results that exceed expectations.
            </p>
            </div>
           <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={basement} className='commcail' alt='' />
            <h3 className='Commercial'>Project Management</h3>
            <p>Our skilled engineers analyse the integrity of your home's structure and devise solutions to support seamless extensions.</p>
             </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={Clientce} className='commcail' alt='' />
            <h3 className='Commercial'>Client-Centric Approach</h3>
            <p>We prioritise your needs and vision, providing personalised attention and communication throughout the entire process.</p>
             </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={Materials} className='commcail' alt='' />
            <h3 className='Commercial'>Superior Quality Materials </h3>
            <p>We use only the finest materials, sourced responsibly, to guarantee durability, longevity, and aesthetic appeal in every construction project.</p>
             </div>
             </div>
             </div>
            </section>  
   
            <section className='our-projects'>
     <div className='container-fluid'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='fields-text'>
                <h2 className='Offering'>We specialise in every aspect of internal <br /> <span className='light'>and external building projects</span></h2>
            <SimilarProjects />
                </div>
             
            </div>
        </div>
    </div>
</section>
   <section className='benefits'>
    <div className='container'>
        <div className='row' id='benerow'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12'>
                <h2>Steps to Our Seamless <span className="lightspan">Construction Process</span></h2>
                <p className='underwhelm'>Step into our world of construction excellence, where meticulous planning, skilled craftsmanship, stringent quality control, and transparent communication unite for unparalleled outcomes.</p>
            </div>
        </div>
        <div className="row" id="features-list">
              <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={talk} className='commcail' alt='' />
            <h3 className='Commercial'>Consultation </h3>
            <p>We begin by listening to your needs and vision, discussing project requirements, timelines, and budget considerations in detail.
              </p>
             </div>
             <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={sketch} className='commcail' alt='' />
            <h3 className='Commercial'>Planning and Design </h3>
            <p>Our expert team collaborates closely with you to develop a comprehensive plan and design tailored to your specifications, ensuring feasibility and functionality.
            </p>
             </div>
             <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={Outbuilding} className='commcail' alt='' />
            <h3 className='Commercial'>Execution </h3>
            <p>With meticulous attention to detail, we bring the project to life, adhering to the agreed-upon timeline and maintaining open communication every step of the way.
            </p>
             </div>
             <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
            <img src={assurance} className='commcail' alt='' />
            <h3 className='Commercial'>Quality Assurance</h3>
            <p>Upon completion, we conduct thorough inspections to ensure that every aspect meets our stringent standards of quality and your satisfaction."
            </p>
             </div>
        </div>
           
        </div>

</section>
<hr />
<section className='innercta'>
  <div className='container'>
    <div className='row'>
      <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12'>
        <div className='cta-text'> 
          <h3>Want to Discuss Your Construction Needs <span className='lightspan'>With Us? Schedule A Consultation Today.</span></h3>
         
        </div>
      </div>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
      
      <div className="button float-right">
      <Queryform />
</div>
      </div>
    </div>
  </div>
</section>  
   
  <FooterThree className="" mainClassName="fix" />
 </>
        
 
  );
};

export default About;
