import React from "react";
import BlogDetails from "../../components/Blog/BlogDetails/BlogDetails";

import Layout from "../../layouts/Layout";

const BlogDetailsPage = () => {
  return (
    <Layout header={1} footer={3} className="" mainClassName="">
      <BlogDetails />

    </Layout>
  );
};

export default BlogDetailsPage;
