import React from "react";
import abbt from "../assets/image/abbt.mp4";
import house from "../assets/image/house.png";
import commercail2 from "../assets/image/commercail2.png";
import commercail3 from "../assets/image/commercail3.png";
import worker from "../assets/image/worker.png";
import Expertise from "../assets/image/Expertise.png";
import task from "../assets/image/task.png";
import professionalism from "../assets/image/professionalism.png";
import Materials from "../assets/image/Materials.png";
import Satisfaction from "../assets/image/Satisfaction.png";
import commercail4 from "../assets/image/commercail4.png";
import Allserivces from "../components/ServicesArea/Allserivces";
import SimilarProjects from "../components/ServicesArea/SimilarProjects";
import homeside from "../assets/image/homeside.png";
import Queryform from "../components/Queryform";
import HeaderTwo from "../components/Headers/HeaderTwo";
import FooterThree from "../components/Footers/FooterThree";
import Preloader from "../components/Preloader/Preloader";
import ScrollTop from "../components/ScrollTop/ScrollTop";


const Home = () => {
  return (
    <>
    <Preloader />
    <ScrollTop />
        <HeaderTwo className="" mainClassName="fix" />
        <section className='latestbnr'>
     <div className='video-container'>
       <video src={abbt} autoPlay loop muted />
      <div className='overlay' />
        </div>
         <div className='contec'>
         <h2>Trusted & reliable construction<br /><span className='lightspan'> experts in the UK</span></h2>
         <div class="form-btn">
         <Queryform />
           </div>
          </div>
        </section>
            
    <section className='abt-sec'>
      <div className='container'>
        <div className='row' id='namebuild'>
           <div className='col-xxl-12 col-12'>
            <div class="hilite-title text-right  text-uppercase text-pop-up-top">
             <strong>NEXABUILD</strong>
              </div>
              </div>
               </div>
               <div className='row'>
                <div className='col-xxl-12 col-12'>
                 <div className='sinox-1'> 
                  <h2>One-stop solution  for all <span className='lightspan'>your construction needs </span></h2>
                   <p className='every'>At NexaBuild Construction, we’re a dedicated team of building contractors catering to the construction needs of residential clients. With a wealth of experience, we deliver high-quality construction work that ensures the utmost satisfaction of our clients. </p>
                    </div>
                    </div>
                    </div>
                        <div className='row' id='two'>
                        <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
                        <img src={house} className='commcail' alt='' />
                            <h3 className='Commercial'>Premium Construction </h3>
                            <p>We specialise in a wide range of residential construction developments, including new builds, house extensions, landscaping, groundwork and much more. </p>
                        </div>
                        <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
                        <img src={commercail2} className='commcail' alt='' />
                            <h3 className='Commercial'>Refurbishment </h3>
                            <p>We provide the highest quality refurbishment services for residential properties to improve your property aesthetics, functionality and value</p>
                        </div>
                        <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
                        <img src={commercail3} className='commcail' alt='' />
                            <h3 className='Commercial'>Project Management </h3>
                            <p>From initial idea to completion and beyond, we offer complete project management services to ensure the timely delivery of your project. </p>
                        </div>
                        <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
                        <img src={commercail4} className='commcail' alt='' />
                            <h3 className='Commercial'>Quality control </h3>
                            <p>We adhere to stringent quality control standards to deliver top-notch construction work that exceeds your expectations. </p>
                        </div>
                        </div>
                    </div>
                </section>
     <Allserivces />
    
    <section className='our-projects'>
     <div className='container-fluid'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='fields-text'>
                <h2 className='Offering'>We specialise in every aspect of internal <br /> <span className='light'>and external building projects</span></h2>
            <SimilarProjects />
                </div>
             
            </div>
        </div>
    </div>
</section>
<section className='whychoose'>
    <div className='container'>
        <div className='row' id='ipsum'>
            <div className='col-xxl-7 col-xl-7 col-md-7 col-12'>
            <div className='eiusmod'>
                <h2>The NexaBuild  Construction <span className='lightspan'> advantage </span></h2>
                <p className='adipiscing'>Discover the advantages that make us your trusted partner for your construction requirements. </p>
                </div>
            </div>
            <div className='col-xxl-5 col-xl-5 col-md-5 col-12'>
            <div class="t-contct">
               <a class="site-button btn-half" href="tel:07710 519713"><span>Contact us</span></a>
                </div>
            </div>
        </div>
        <div className='row' id='three'>
        <div className='col-xxl-5 col-xl-4 col-lg-4 col-md-12 col-12'>
        <img src={homeside} className='homeside' alt='' />
        </div>
        <div className='col-xxl-7 col-xl-8 col-lg-8 col-md-12 col-12'>
        
            <div className='row' id='dolore'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='dolor'>
              <img src={worker} className='whychoose1' alt='' />
                <h3 className='Commercial'>Unparalleled workmanship </h3>
                  <p>At NexaBuild Construction we take pride in every job. Our building teams are dedicated to providing high-quality workmanship on time and within budget. </p>
                  </div>
                 
                            <div className='dolor'>
                        <img src={task} className='whychoose1' alt='' />
                            <h3 className='Commercial'>On-schedule completion</h3>
                            <p>We’re construction experts who pride ourselves on delivering quality construction work on time and within your budget specifications. 
                                </p>
                            </div>
                            <div className='dolor'>
                        <img src={Materials} className='whychoose1' alt='' />
                            <h3 className='Commercial'>High-quality materials</h3>
                            <p>We source materials from trusted suppliers known for their innovation, design and manufacturing standards to deliver impeccable finishes that exceed expectations. 
                            </p>
                            </div>
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                  <div className='dolor'>
                        <img src={Expertise} className='whychoose1' alt='' />
                          <h3 className='Commercial'>In-depth expertise</h3>
                            <p>With deep expertise cultivated through years of experience in the construction sector, we have a successful track record of delivering projects of varying scales and complexities. </p>
                        </div>
                        <div className='dolor'>
                        <img src={professionalism} className='whychoose1' alt='' />
                            <h3 className='Commercial'>Qualified professionals </h3>
                            <p>With us, you can rest assured that a team of qualified and certified professionals will be working on your project. 
                            </p>
                            </div>
                            <div className='dolor'>
                        <img src={Satisfaction} className='whychoose1' alt='' />
                            <h3 className='Commercial'> Client satisfaction assured </h3>
                            <p>Our clients are our topmost priority. From our initial first meeting to the completion and evaluation of our work, we will keep you informed throughout the entire process. </p>
                            </div>
                        </div>
                        
            </div>
        </div>
                </div>
    </div>
</section>  
<section className='calltoaction'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
                <div className='newbox'>
                   <ul>
                    <li><span><i class="fa-solid fa-mobile-screen"></i></span><a href='tel:07773 005221'>07773 005221</a></li>
                   </ul> 
                   <h2> Ready to create your dream space? <br /><span className='lightspan'>Reach out to us today! </span></h2>
               <Queryform />
                </div>
            </div>
        </div>
    </div>
</section>          
        <FooterThree className="" mainClassName="fix" />
    </>
  );
};

export default Home;
