import React from "react";
import { NavLink } from 'react-router-dom';
import logo from "../../assets/image/logo-1.png";

const FooterThree = () => {
  return (
    <footer className="site-footer footer-large  footer-light footer-wide">
    {/* FOOTER BLOCKES START */}
    <div className="footer-top">
        <div className="container">
            <div className="row">
                {/* ABOUT COMPANY */}
                <div className="col-lg-3 col-md-12  col-12">
                    <div className="widget widget_about">
                        {/*<h4 class="widget-title">About Company</h4>*/}
                        <div className="logo-footer clearfix p-b15">
                            <NavLink to={"/"}>
                            <img src={logo} className="Inteshape" alt="Inteshape" />
                                {/* <img src={require('./../../images/logo-1.png')} className="Inteshape" alt="Inteshape" /> */}
                            </NavLink>
                        </div>
                        <p>From building refurbishments and house extensions to damp coursing and basement conversions, our consistent high-quality work and exceptional service ensure our customers return to us repeatedly.</p>
                        {/* <ul className="social-icons  sx-social-links">
                        
                            <li><a href="/"><i class="fa-brands fa-facebook-f"></i></a></li>
                            <li><a href="/"><i class="fa-brands fa-x-twitter"></i></a></li>
                            <li><a href="/"><i class="fa-brands fa-instagram"></i></a></li>
                        </ul> */}
                    </div>
                </div>
              
             
                {/* USEFUL LINKS */}
                <div className="col-lg-3 col-md-4 col-12">
                    <div className="widget widget_services inline-links">
                        <h5 className="widget-title">Useful Links</h5>
                        <ul>
                            <li><NavLink to={"/"}>Home</NavLink></li>
                            <li><NavLink to={"/About"}>About Us</NavLink></li>
                            <li><NavLink to={"/Gallery"}>Gallery</NavLink></li>
                            <li><NavLink to={"/Contact"}>Contact Us</NavLink></li>
                            <li><NavLink to={"/privacypolicy"}>Privacy Policy</NavLink></li>
                            <li><NavLink to={"/Terms-conditions"}>Terms & Conditions</NavLink></li>
                        </ul>
                    </div>
                </div>
                
                {/* USEFUL LINKS */}
                <div className="col-lg-3 col-md-4 col-12">
                    <div className="widget widget_services inline-links">
                        <h5 className="widget-title">Services</h5>
                        <ul>
                            <li><NavLink to={"/Loft-Conversions"}>Loft Conversions</NavLink></li>
                            <li><NavLink to={"/Renovation"}>Renovation</NavLink></li>
                            <li><NavLink to={"/Kitchens"}>Kitchens</NavLink></li>
                            <li><NavLink to={"/New-Builds"}>New Builds</NavLink></li>
                            <li><NavLink to={"/House-Extensions"}>House Extensions</NavLink></li>
                            <li><NavLink to={"/Driveways"}>Driveways</NavLink></li>
                        </ul>
                    </div>
                </div>
                {/* CONTACT US */}
                <div className="col-lg-3 col-md-4  col-12">
                    <div className="widget widget_address_outer">
                        <h5 className="widget-title">Contact Us</h5>
                        <ul className="widget_address">
                            <li><span className="footericon"><i class="fa-solid fa-location-dot"></i></span>162 Market St, M43 7AA</li>
                            <li><span className="footericon"><i class="fa-regular fa-envelope"></i></span><a href='mailto:info@nexabuild-construction.co.uk'>info@nexabuild-construction.co.uk</a></li>
                            <li><span className="footericon"><i class="fa-solid fa-mobile-screen-button"></i></span> <a href='tel:07773 005221'>07773 005221</a></li>
                            <li><span className="footericon"><i class="fa-solid fa-mobile-screen-button"></i> </span><a href='tel:07710 519713'>07710 519713</a></li>
                                                                  </ul>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="container-fluid">
            <div className="call-to-action-wrap">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-sm-12">
                        <div className="call-to-action-left">
                            <h5 className="text-uppercase m-b10 m-t0">Subscribe to our newsletter!</h5>
                            <span>Never Miss Anything From 7xtheme By Signing Up To Our Newsletter.</span>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="call-to-action-right">
                            <div className="widget_newsletter">
                                <div className="newsletter-bx">
                                    <form role="search" method="post" action="">
                                        <div className="input-group">
                                            <input name="news-letter" className="form-control" placeholder="ENTER YOUR EMAIL" type="text" />
                                            <span className="input-group-btn">
                                                <button type="button" className="site-button"><i className="fa fa-paper-plane-o" /></button>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
    </div>
    {/* FOOTER COPYRIGHT */}
    <div className="footer-bottom overlay-wraper">
        <div className="overlay-main" />
        <div className="container">
            <div className="clearfix">
                <div className="sx-footer-bot-center">
                    <span className="copyrights-text">© 2024 <a href="/">Nexabuild-Construction</a>. All rights reserved</span>
                </div>
            </div>
        </div>
    </div>
</footer>
  );
};

export default FooterThree;
