import React from "react";
import SlickSlider from "../SlickSlider/SlickSlider";
import { Link } from "react-router-dom";
import gallery1 from "../../assets/image/gallery1.webp";
import gallery2 from "../../assets/image/gallery2.webp";
import gallery3 from "../../assets/image/gallery3.webp";
import gallery4 from "../../assets/image/gallery4.webp";
import gallery5 from "../../assets/image/gallery5.webp";
import gallery6 from "../../assets/image/gallery6.webp";


const SimilarProjects = () => {

    const slick_settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: true,
                },
              },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          },
        ],
      };

  return (
    <div className="section-full homeproject  inner-page-padding">
    <div className='container-fluid'>
            <div className="project-slider allservices">
            <SlickSlider settings={slick_settings} >
          <div className="item">
             <div class="simlai">
             <img src={gallery1} className='serpi' alt='' />
              </div>
            </div>
            <div className="item">
            <div class="simlai">
             <img src={gallery2} className='serpi' alt='' />
              </div>
            </div>
            <div className="item">
            <div class="simlai">
             <img src={gallery3} className='serpi' alt='' />
              </div>
            </div>
            <div className="item">
            <div class="simlai">
             <img src={gallery4} className='serpi' alt='' />
             </div>
            </div>
           
            <div className="item">
            <div class="simlai">
             <img src={gallery5} className='serpi' alt='' />
              </div>
            </div>
            <div className="item">
            <div class="simlai">
               <img src={gallery6} className='serpi' alt='' />
            </div>
            </div>
            </SlickSlider>
            </div>
        </div>
    </div>
  
  );
};

export default SimilarProjects;
